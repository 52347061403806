@import url("https://fonts.googleapis.com/css?family=Jost&display=swap");


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
  font-family: "Jost", sans-serif;
  background: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root{
  --beige: #EAE2B7;
  --orange: #F77F00;
  --yellow: #FCBF49;
  --red: #D62828;
  --blue: #003049;
  --black: #000000;
  --white: #ffffff;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}