.background-blobs {
    position: absolute; /* Ensures it covers the whole page */
    top: 0;
    left: 0;
    width: 100%;
    height: 200vh; /* Extends beyond viewport for scrolling effect */
    overflow: hidden;
    z-index: -1;
}

.blob {
    position: absolute; /* Makes sure the blobs are placed properly */
    width: 350px;
    height: 350px;
    background-color: var(--beige);
    opacity: 0.05;
    /* background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 80%); */
    /* filter: blur(1px); */
    border-radius: 50%;
    animation: float 50s infinite alternate ease-in-out;
}

/* Positioning blobs at different scroll points */
.blob:nth-child(1) { top: 40vh; left: 10%; }
.blob:nth-child(2) { top: 100vh; right: 8%; }
.blob:nth-child(3) { top: 150vh; left: 5%; }
.blob:nth-child(4) { top: 180vh; right: 15%; }

/* Floating animation */
@keyframes float {
    from { transform: translateY(20px) translateX(0); }
    to { transform: translateY(-50px) translateX(10px); }
}
