.founder-note-container {
    margin: 10% 15% 5% 15%;
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
}

.founder-img {
    height: 450px;
    border: none;
    /* outline: 3px solid rgba(255, 255, 255, 0.8);
    outline-offset: -10px; */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    backdrop-filter: blur(10px);
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
   
}

.founder-img:hover {
    transform: scale(1.02);
    box-shadow: 0px 15px 40px rgba(255, 255, 255, 0.2);
}

.founder-note-heading {
    color: var(--white);
    margin-bottom: 5%;
 
}


.founder-note-text {
    text-align: justify;
    color: var(--white);
    line-height: 1.5;
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.founder-name{
    color: var(--orange);
    text-align: right;
    margin-top: 5%;
    font-size: 15px;
}

@media (max-width: 1050px) {

    .founder-note-container {
        margin: 15% 5% 20% 5%;
        display: flex;
        flex-direction: row;
        gap: 50px;
        align-items: center;
    }

    .founder-img {
        height: 400px;
        /* outline: 2px solid rgba(255, 255, 255, 0.5);
        outline-offset: -8px; */
        box-shadow: 0px 5px 20px rgba(255, 255, 255, 0.1);
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        border-top-right-radius: 25px;
    }

    .founder-note-text {
        text-align: justify;
        color: var(--white);
        line-height: 1.4;
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 100;
    }

    .founder-name{
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    .founder-note-container {
        /* margin: 10% 15% 5% 15%; */
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
    }

    .founder-img {
        height: 250px;
        /* outline: 3px solid rgba(255, 255, 255, 0.5);
        outline-offset: -8px; */
        box-shadow: 0px 5px 20px rgba(255, 255, 255, 0.1);

    }

    .founder-note-text {
        text-align: justify;
        color: var(--white);
        font-size: 12px;
        font-weight: 100;
    }
}