.navbar-small-screen {
    display: none; 
}

.logo-image-small-screen {
    height: 80px;
}

.narbar-links-small-screen {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10%;
    margin-right: 3%;
    align-items: center;
    align-content: center;
}

.nav-link-small-screen {
    color: var(--beige);
    font-size: 16px;
    font-weight: 500;
    padding: 5%;
    cursor: pointer;
    letter-spacing: 1px;
    border-bottom: 1px solid var(--yellow);
}

.navbar-hambugr-menu{
    font-size: 30px !important;
    color: var(--white) !important;
}

.navbar-toggle{
    position: absolute;
    padding: 5%;
    top: 50px;
    height: 48vh;
    width: 100%;
    left:0;
    background-color: var(--black);
    color: var(--white);
    transition: height 1s ease-in-out;
}

@media (max-width: 900px) {
    .navbar-small-screen {
        position: fixed;
        top: 0;
        left: 0;
        height: 50px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 100;
        background-color: var(--black);
        align-items: center;
        align-content: center;
        padding-left: 5%;
    }
}